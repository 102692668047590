/* << Copyright 2022 Iñigo Tamayo Uria, Ana Domínguez Fanlo, Mikel Joseba Zorrilla Berasategui, Héctor Rivas Pagador, Sergio Cabrero Barros, Juan Felipe Mogollón Rodríguez and Stefano Masneri. >>
This file is part of Orkestralib.
Orkestralib is free software: you can redistribute it and/or modify it under the terms of the GNU Lesser General Public License as published by the Free Software Foundation, either version 3 of the License, or (at your option) any later version.
Orkestralib is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Lesser General Public License for more details.
You should have received a copy of the GNU Lesser General Public License along with Orkestralib. If not, see <https://www.gnu.org/licenses/>. */
import { JanusAudioStreaming } from '../libs/streaming.js'

export class WCStreaming extends HTMLElement {
  static get observedAttributes() {
    return ['input', 'config'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {

      this[name] = newValue;

    }
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML += "<audio autoplay controls></audio>";
    console.log("new constructor");
  }
  get config() { return this.getAttribute('config'); }
  set config(value) {
    if (value != "") {
      let configJson = JSON.parse(value);
      this.janusStream = JanusAudioStreaming(this.shadowRoot, configJson.janusServer);
      
    }
  }
  get options() { return this.getAttribute('options'); }
  set options(value) {
    this.setAttribute('options', value);
    //setTimeout(()=>{this.setConfig();},500);
  }
  get input() { return this.getAttribute('input'); }
  set input(value) {
    this.setAttribute('input', value);
    if (value!="" ){
      this.janusStream.init(this.input, { debug: true });
    }
    //setTimeout(()=>{this.setConfig();},500);
  }
  async connectedCallback() {
    this.__initialized = true;
  }

  publish(options) {
    //  this.unpublish();
    if (options instanceof MouseEvent)
      this.janusStream .init(this.input, this.flags);
    else this.janusStream.init(this.input, options || this.flags);
  }
  unpublish() {
    this.janusStream.stop();
  }
  setConfig() {
    let conf = {};
    try {
      conf = JSON.parse(this.options);
      this.flags = conf;
    }
    catch (ex) {
      console.log(ex);
    }

    if (typeof conf.bitrate !== "undefined") this.shadowRoot.querySelector('#bitrate').value = conf.bitrate;
    if (typeof conf.simulcast !== "undefined") this.shadowRoot.querySelector('#simulcast').checked = conf.simulcast;

  }
  async showConfig() {

  }
  configChange(ev) {

    this.flags.simulcast = this.shadowRoot.querySelector('#simulcast').checked;
    this.flags.bitrate = parseInt(this.shadowRoot.querySelector('#bitrate').value);

  }
  render() {


  }
  restream(){
    if (this.input!="" && this.janusStream){
      this.janusStream.start(parseInt(this.input));
    }
  }
}

customElements.define('x-streaming', WCStreaming);
