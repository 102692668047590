import { Janus } from './janus.js';

var JanusAudioStreaming = function (dom, janusServer) {
    let server = null
    if (window.location.protocol === 'http:') {
        server = "ws://" + (janusServer || window.location.hostname) + ":8188/janus";
    }
    else {
        server = "wss://" + (janusServer || window.location.hostname) + ":8989/janus";
    }
    var janus = null;
    var streaming = null;
    var opaqueId = "streaming-" + Janus.randomString(12);
    let selectedStream = -1;

    function init(aid, _options) {
        // Initialize the library (all console debuggers enabled)
        selectedStream = aid;
        const { debug } = _options
        Janus.init({
            debug: debug, callback: function () {
                // Use a button to start the demo

                // Make sure the browser supports WebRTC
                if (!Janus.isWebrtcSupported()) {
                    alert("No WebRTC support... ");
                    return;
                }
                // Create session
                janus = new Janus(
                    {
                        server: server,
                        success: function () {
                            // Attach to Streaming plugin
                            janus.attach(
                                {
                                    plugin: "janus.plugin.streaming",
                                    opaqueId: opaqueId,
                                    success: function (pluginHandle) {
                                        streaming = pluginHandle;
                                        Janus.log("Plugin attached! (" + streaming.getPlugin() + ", id=" + streaming.getId() + ")");
                                        // Setup streaming session
                                        updateStreamsList();
   
                                    },
                                    error: function (error) {
                                        Janus.error("  -- Error attaching plugin... ", error);
                                    },
                                    iceState: function (state) {
                                        Janus.log("ICE state changed to " + state);
                                    },
                                    webrtcState: function (on) {
                                        Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                                    },
                                    onmessage: function (msg, jsep) {
                                        Janus.debug(" ::: Got a message :::", msg);
                                        var result = msg["result"];
                                        if (result) {
                                            if (result["status"]) {
                                                var status = result["status"];
                                              if (status === 'stopped')
                                                    stopStream();
                                            }
                                        } else if (msg["error"]) {
                                            stopStream();
                                            return;
                                        }
                                        if (jsep) {
                                            Janus.debug("Handling SDP as well...", jsep);
                                            var stereo = (jsep.sdp.indexOf("stereo=1") !== -1);
                                            // Offer from the plugin, let's answer
                                            streaming.createAnswer(
                                                {
                                                    jsep: jsep,
                                                    // We want recvonly audio/video and, if negotiated, datachannels
                                                    media: { audioSend: false, videoSend: false, data: false },
                                                    customizeSdp: function (jsep) {
                                                        if (stereo && jsep.sdp.indexOf("stereo=1") == -1) {
                                                            // Make sure that our offer contains stereo too
                                                            jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                                                        }
                                                    },
                                                    success: function (jsep) {
                                                        Janus.debug("Got SDP!", jsep);
                                                        var body = { request: "start" };
                                                        streaming.send({ message: body, jsep: jsep });
                                                    },
                                                    error: function (error) {
                                                        Janus.error("WebRTC error:", error);
                                                        alert("WebRTC error... " + error.message);
                                                    }
                                                });
                                        }
                                    },
                                    onremotestream: function (stream) {
                                        Janus.debug(" ::: Got a remote stream :::", stream);
                                        Janus.attachMediaStream(dom.querySelector('audio'), stream);
                                        console.log(dom.querySelector('audio'));
                                        dom.querySelector('audio').play();
                                        dom.querySelector('audio').volume = 1;
                                

                                    },
                                    ondataopen: function (data) {
                                        Janus.log("The DataChannel is available!");

                                    },
                                    ondata: function (data) {
                                        Janus.debug("We got data from the DataChannel!", data);
                                        console.log(data);
                                    },
                                    oncleanup: function () {
                                        Janus.log(" ::: Got a cleanup notification :::");
                                        //  remove audio tag ?
                                    }
                                });
                        },
                        error: function (error) {
                            Janus.error(error);
                        
                        },
                        destroyed: function () {
                            window.location.reload();
                        }
                    });
                setTimeout(() => { startStream(aid); }, 500);
            }
        });
    }

    function stop() {
        console.warn("streaming plugin want be stopped");
      //  streaming.hangup();
      //  janus.destroy()
    }


    function updateStreamsList() {
        var body = { request: "list" };
        Janus.debug("Sending message:", body);
        streaming.send({
            message: body, success: function (result) {
              
                if (!result) {
                    alert("Got no response to our query for available streams");
                    return;
                }
                if (result["list"]) {
                    var list = result["list"];
                    Janus.log("Got a list of available streams");
                    if (list && Array.isArray(list)) {
                        list.sort(function (a, b) {
                            if (!a || a.id < (b ? b.id : 0))
                                return -1;
                            if (!b || b.id < (a ? a.id : 0))
                                return 1;
                            return 0;
                        });
                    }
                    Janus.debug(list);
                    console.log(list);
                }
            }
        });
    }

    function getStreamInfo() {
        if (!selectedStream)
            return;
        // Send a request for more info on the mountpoint we subscribed to
        var body = { request: "info", id: parseInt(selectedStream) || selectedStream };
        streaming.send({
            message: body, success: function (result) {
                if (result && result.info && result.info.metadata) {
                    console.log(result.info.metadata);
                }
            }
        });
    }

    function startStream(_selectedStream) {
        if (!streaming) return;
      
            if (_selectedStream)
                selectedStream = _selectedStream;
            Janus.log("  #" + selectedStream);
            if (!selectedStream) {
                alert("Select a stream from the list");
                return;
            }
            var body = { request: "watch", id: parseInt(selectedStream) || selectedStream };
            streaming.send({ message: body });
            getStreamInfo();
   
    }

    function stopStream() {
        var body = { request: "stop" };
        streaming.send({ message: body });
        //streaming.hangup();
        //simulcastStarted = false;
    }

    return {
        init: init,
        stop: stop,
        start: startStream
    }

}

export {JanusAudioStreaming}